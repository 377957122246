<template>
  <div class="lht-header">
    <div class="lht-header-logo">
      <div class="wrapper">
        <div class="clearfix">
          <div class="pull-left">
            <a href="/" class="logo"><img style="width: 230px; height: 60px;" src="../../assets/image/logo.png" alt=""></a>
          </div>
          <div class="lht-header-search pull-right">
            <el-input style="width: 300px; margin-right: 10px;" class="lht-header-search-input" size="medium" v-model="search" placeholder="输入关键词">
            </el-input>
            <div class="btn-search" @click="handleSearch">搜索</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 导航列表 -->
    <div class="lht-header-nav">
      <div class="wrapper">
        <div class="lht-header-nav-list clearfix">
          <div
            :class="index == active ? 'lht-header-nav-list-item active pull-left' : 'lht-header-nav-list-item pull-left'"
            v-for="(item, index) in nav.slice(0, 9)" :key="index"
            @click="handleToggle(item, index)"
            >
            <div v-if="item.isHide == 1">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetHeaderNavList } from '@/api/http'
export default {
  name: 'MyHeader',
  data () {
    return {
      search: '',
      active: 0,
      nav: []
      // { name: '首页', path: '/home' },
      // { name: '公司概况', path: '/' },
      // { name: '公司大事记', path: '/' },
      // { name: '行业动态', path: '/' },
      // { name: '服务与产品', path: '/' },
      // { name: '解决方案', path: '/' },
      // { name: '合作交流', path: '/' },
      // { name: '联系我们', path: '/' }
    }
  },
  watch: {
    $route: {
      handler(val, oldval) {
        if (val.query.id) {
          for (let i = 0; i < this.nav.length; i++) {
            if (this.$route.query.id == this.nav[i].id) {
              this.active = i
              return false
            } else {
              this.active = 10000000
            }
          }
        }
      }
    }
  },
  created () {
    if (this.$route.query.id) {
      for (let i = 0; i < this.nav.length; i++) {
        if (this.$route.query.id == this.nav[i].id) {
          var index = i
        }
      }
      this.active = index
    }
    this.HeaderNavListGet()
  },
  methods: {
    // 获取导航
    HeaderNavListGet () {
      GetHeaderNavList().then(res => {
        if (res.code !== '000') return this.$message({ type: 'error', message: res.msg })
        const data = []
        for (let i = 0; i < res.result.length; i++) {
          if (res.result[i].isHide == 1) {
            data.push(res.result[i])
          }
        }
        for (let j = 0; j < data.length; j++) {
          if (this.$route.query.id) {
            if (this.$route.query.id == data[j].id) {
              this.active = j
            }
          }
        }
        this.nav = data
      }).catch((err) => {
        console.log(err)
      })
    },
    // 搜索跳转
    handleSearch () {
      if (this.search) {
        this.active = 1000000000
        this.$router.push({
          path: '/search',
          query: { content: this.search }
        })
      }
    },
    // 切换导航跳转
    handleToggle (item, index) {
      if (index === this.active) return false
      if (item.url && item.url != '/') {
        window.open(item.url)
      } else {
        if (item.url) {
          this.active = index
          this.$router.push({ path: '/' })
        } else {
          if (item.type == 2) {
            const { href } = this.$router.resolve({
              path: '/news/details',
              query: { id: item.id, type: 2 }
            })
            window.open(href, '_blank')
          } else {
            if (item.isTarget == 1) {
              const { href } = this.$router.resolve({
                path: '/news',
                query: {
                  content: item.name,
                  id: item.id
                }
              })
              window.open(href, '_blank')
            } else {
              this.active = index
              this.$router.push({
                path: '/news',
                query: {
                  content: item.name,
                  id: item.id
                }
              })
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.lht-header {
  .lht-header-logo {
    width: 100%;
    height: 120px;
    line-height: 120px;
  }
  .lht-header-nav {
    width: 100%;
    height: 48px;
    background-color: #155BDD;
    color: #fff;
    .lht-header-nav-list {
      overflow: hidden;
    }
    // .lht-header-nav-list-item + .lht-header-nav-list-item {
    //   margin-left: 21px;
    // }
    .lht-header-nav-list-item:hover {
      background: #0546C0;
    }
    .lht-header-nav-list-item.active {
      background: #0546C0;
    }
    .lht-header-nav-list-item {
      font-size: 16px;
      cursor: pointer;
      width: 122px;
      text-align: center;
      // padding: 0 20px;
      line-height: 48px;
      color: #fff;
    }
  }
}

</style>
