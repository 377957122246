<template>
  <div class="lht-container">
    <My-Header></My-Header>
    <router-view />
    <My-Footer></My-Footer>
  </div>
</template>

<script>
import MyHeader from '@/components/header/MyHeader'
import MyFooter from '@/components/footer/MyFooter'
export default {
  name: 'Index',
  components: { MyHeader, MyFooter },
  data () {
    return {
      msg: '陆航通'
    }
  },
  created () {
    if (!window.localStorage.getItem('token')) {
      window.localStorage.setItem('token', '')
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
</style>
