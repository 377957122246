<template>
  <div class="lht-footer">
    <div class="lht-footer-top">
      <div class="wrapper">
        <div class="lht-footer-list clearfix">
          <ul class="lht-footer-list-item pull-left">
            <li class="nth-1">公司概况</li>
            <li class="navItem" @click="toDetails('a7a0e9c1e14d80b88367cc42005da0b7')">领导寄语</li>
            <li class="navItem" @click="toDetails('884e0e5cef66266dbdd3f1ddb20b9c95')">公司简介</li>
            <li class="navItem" @click="toDetails('da84fb92a8418616b0542d61d5b95098')">组织架构</li>
            <li class="navItem" @click="toDetails('210c6f75104c604a0ef1d9b252217f5b')">资质与能力</li>
          </ul>
          <ul class="lht-footer-list-item pull-left">
            <li class="nth-1">产品与服务</li>
            <li class="navItem" @click="toDetails('a5be7e8eb7a4b3fa82395d35e57a6097')">货运平台</li>
            <li class="navItem" @click="toDetails('7b2b8360b2ac1e4869b6bcb9b908145f')">TMS</li>
            <li class="navItem" @click="toDetails('04b45cc41b8f8557795fe6d7144c23cb')">运输方案</li>
            <li class="navItem" @click="toDetails('034d017b1530f6a3724636a58743bda3')">路径规划</li>
            <li class="navItem" @click="toDetails('efb2cc0d9b975019d7c12a01f6400a4d')">培训服务</li>
          </ul>
          <ul class="lht-footer-list-item pull-left">
            <li class="nth-1">解决方案</li>
            <li class="navItem" @click="toNews('/news?content=解决方案&id=b207a85e21895f5ea0c11ef35da8a6eb&cid=a650d72583a7b7bc2654b2cdb498af27')">交通信息化</li>
            <li class="navItem" @click="toNews('/news?content=解决方案&id=b207a85e21895f5ea0c11ef35da8a6eb&cid=fdf28573bf6573ed8611abdf62ed3035')">城市信息化</li>
            <li class="navItem" @click="toNews('/news?content=解决方案&id=b207a85e21895f5ea0c11ef35da8a6eb&cid=689c9521920f2d85578c87f3a7d0cdb1')">物流信息化</li>
            <li class="navItem" @click="toNews('/news?content=解决方案&id=b207a85e21895f5ea0c11ef35da8a6eb&cid=59f19d4de236c06f9e18e73b09d800a6')">楼宇信息化</li>
          </ul>
          <ul class="lht-footer-list-item pull-left">
            <li class="nth-1">联系我们</li>
            <li class="tel">
              <img src="../../assets/image/tel.png" alt="">
              <span>010-68004560</span>
            </li>
            <li>北京路航通科技发展有限公司</li>
            <!-- <li>联系邮箱：xxxxxx@ctigo.com</li> -->
            <li>公司地址：北京市西城区鸿儒大厦1A</li>
          </ul>
          <!-- <div class="lht-footer-list-item pull-right" style="margin-left: 0;">
            <div class="nth-1">特运通 APP</div>
            <div class="lht-footer-ewm clearfix">
              <div class="lht-footer-ewm-item pull-left">
                <img src="../../assets/image/qrcode_1.png" alt="">
                <span>小程序</span>
              </div>
              <div class="lht-footer-ewm-item pull-left">
                <img src="../../assets/image/qrcode_2.png" alt="">
                <span>公众号</span>
              </div>
              <div class="lht-footer-ewm-item pull-left">
                <img src="../../assets/image/qrcode_4.png" alt="">
                <span>为客户提供货运交易交流平台，用最优质的服务提升物流转运效率</span>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="lht-footer-bottom">
      <div class="wrapper"><a href="https://beian.miit.gov.cn" target="_blank">京ICP备2021001021号-3</a></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MyFooter',
  data () {
    return {}
  },
  created () {},
  methods: {
    toDetails (id) {
      const { href } = this.$router.resolve({
        path: '/news/details',
        query: { id: id }
      })
      window.open(href, '_blank')
    },
    toNews (url) {
      if (url) {
        this.$router.push({ path: url })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.navItem{
  cursor: pointer;
  padding-bottom: 3px;
}
.navItem:hover{
  font-weight: bold;
}
.lht-footer {
  width: 100%;
  .lht-footer-top {
    height: 244px;
    background-color: #001048;
    .lht-footer-list {
      padding-top: 38px;
      color: #F0F0F0;
      font-size: 12px;
      .lht-footer-list-item + .lht-footer-list-item {
        margin-left: 150px;
        // margin-left: 125px;
      }
      .lht-footer-list-item {
        .nth-1 {
          font-size: 16px;
          margin-bottom: 10px;
          line-height: 20px;
          color: #e9e9e9;
        }
        .tel {
          font-size: 24px;
          line-height: 28px;
          img {
            margin-right: 6px;
            width: 24px;
            height: 24px;
          }
          span {
            vertical-align: middle;
          }
        }
        li {
          line-height: 20px;
        }
        .lht-footer-ewm-item + .lht-footer-ewm-item {
          margin-left: 35px;
        }
        .lht-footer-ewm-item {
          width: 100px;
          text-align: center;
          img {
            margin-bottom: 6px;
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }
  .lht-footer-bottom {
    text-align: center;
    font-size: 12px;
    color: #999999;
    height: 50px;
    line-height: 50px;
    background-color: #0B1022;
  }
}
</style>
